.daily-list {
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: 0.4rem;
  min-height: 10rem;
  width: 100%;
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  &__header {
    margin-left: 8rem;
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    .time-sheet__content__list__item__col {
      background: var(--primary-white) !important;
      border-bottom: 0.2rem solid #f0f3f8 !important;
      border-radius: 0.8rem 0.8rem 0 0;
      padding: 1rem 1.8rem;
      box-shadow: -1px 0px 11px rgba(122, 128, 141, 0.15);
      border: 0.1rem solid var(--primary-gray-3) !important;
      border-left: 0 !important;
      border-top: 0 !important;
      .name {
        color: #2d3742;
      }
      .totalTime {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 0.2rem;
        font-weight: 400;
        color: var(--primary-gray-2);
      }
    }
  }

  &__item {
    display: flex;
    // justify-content: space-between;
    width: 100%;

    &__col {
      width: 100%;
      border: 0.1rem solid var(--primary-gray-3);
      border-left: 0;
      border-top: 0;
      min-height: 6.4rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--primary-white);
      color: var(--primary-gray-1);
      position: relative;
      &:first-child {
        min-width: 8rem;
        max-width: 8rem;
        border: 0;
        background: var(--primary-gray);
      }
      .tag {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: var(--primary-gray-1);
        border-radius: 0.4rem;
        cursor: pointer;
        padding: 2.4rem 0;
        position: absolute;
        top: 0;
        z-index: 1;
        max-width: 54.3rem;
        width: 100%;
        &__item {
          padding: 0 2.4rem;
        }
        &__title {
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.4rem;
          margin-bottom: 1.9rem;
        }
        &__time {
          display: flex;
          margin-top: 0.4rem;
          &__item {
            margin-right: 1.9rem;
            font-weight: 700;
            &:last-child {
              margin-right: 0;
            }
            &__label {
              font-size: 1.2rem;
              line-height: 1.6rem;
              font-weight: 400;
              margin-bottom: 0.2rem;
            }
          }
        }
        img {
          margin: 1rem 0;
          max-width: 54rem;
          width: 100%;
        }
      }
    }
  }
}

.tooltip__text {
  margin-bottom: 2.4rem;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &__delete {
    color: #e93c3c;
  }
}

.dialog-daily-detail {
  width: 48.8rem;
  &__content {
    padding: 3.5rem 4rem;
    &__time {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 3rem;
      color: var(--primary-gray-1);
    }
  }
  .item-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    img {
      height: 6.4rem;
      width: 6.4rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.4rem;
    }
    .item-code {
      color: var(--primary-gray-2);
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  &__table {
    border: 0.1rem solid #e0e6f0;
    border-radius: 0.4rem;
    padding: 2.4rem 1.8rem;
    font-size: 1.6rem;
    line-height: 2rem;
    &__label {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;
      color: var(--primary-gray-1);
    }
    img {
      margin-bottom: -0.5rem;
    }
  }
  &__group {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-gray-2);
    background-color: var(--primary-gray);
    font-weight: 700;
    padding: 0.5rem 2.4rem;
    &__btn {
      display: flex;
      align-items: center;
      border-left: 0.1rem solid #e0e6f0;
      margin-left: 2.4rem;
      cursor: pointer;
      &:first-child {
        border: 0;
      }
    }
  }
}
