.single-range {
  width: 100%;
  height: 4.8rem;
  .SingleDatePicker {
    width: 100%;
  }
  .SingleDatePicker * {
    outline: none;
  }
  .SingleDatePickerInput__withBorder {
    width: 100%;
    border-radius: 0.4rem;
    border-color: rgba(0, 0, 0, 0.23);
    height: 4.8rem;
    &:hover {
      border-color: var(--primary-blue-base);
    }
  }
  .SingleDatePickerInput_calendarIcon {
    // float: right;
  }
  .DateInput_input__focused {
    border: 0;
  }
  .CalendarDay__selected_span {
    background: var(--secondary-blue-2);
    border: 0.1rem solid var(--secondary-blue-1);
  }
  .CalendarDay__selected {
    background: var(--primary-blue-base);
    border: 0.1rem double #0e76d9;
  }
  .DateInput {
    width: 70%;
  }
  .DateInput_input {
    font-size: 1.6rem;
    padding: 0.8rem 1.6rem 0 !important;
  }
  .DateInput_fangStroke {
    display: none;
  }
  .SingleDatePicker_picker {
    z-index: 3;
  }
  .DateInput_input__disabled {
    font-style: normal;
  }
}
