.time-sheet-history-detail {
  font-family: "Nunito Sans";
  .header-list-page {
    padding: 0 !important;
    background: #f0f3f8 !important;
  }
  &-header {
    color: #6c778d;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      width: 100%;
      padding: 1.4rem 2.4rem;
      background-color: var(--primary-white);
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
    }

    .val {
      color: #272b2f;
      padding-top: 0.1rem;
    }
    &__employee {
      width: 28%;
      display: flex;
      align-items: center;
      .item-name {
        font-weight: 700;
        display: flex;
        align-items: center;
        line-height: 1.8rem;
        font-size: 1.6rem;
        color: #2d3742;
        img {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 0.8rem;
        }
      }
      .item-code {
        color: #7a869a;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
    &__wage {
      width: 14%;
    }
    &__ot {
      width: 14%;
      display: flex;
      flex-direction: column;
      .text-color {
        color: #0354a0;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 0.1rem;
        width: fit-content;
        background-color: transparent;
        border: 0;
      }
    }
    &__weekly {
      width: 14%;
    }
    &__paid {
      width: 14%;
    }
    &__total {
      width: 16%;
      border-left: 0.1rem solid #e0e6f0;
      padding-left: 2.4rem;
    }
  }
  &__date {
    width: 14%;
  }
  &__checkin {
    width: 14%;
  }
  &__checkout {
    width: 14%;
  }
  &__break-time {
    width: 14%;
  }
  &__regular {
    width: 14%;
  }
  &__overtime {
    width: 14%;
  }
  &__total {
    width: 14%;
    padding-left: 2.4rem;
  }
  &__edit {
    width: 2%;
  }

  &__total-listing {
    position: sticky;
    bottom: 0;
    .total {
      display: flex;
      padding: 0.6rem 2.4rem;
      background-color: var(--primary-gray);
      border-bottom: 0.2rem solid #f0f3f8;
    }
    &__title {
      width: 56%;
      font-weight: 700;
    }
    &__col-1 {
      width: 14%;
    }
    &__col-2 {
      width: 14%;
    }
    &__col-3 {
      width: 16%;
      padding-left: 2.4rem;
    }
  }
  .item-list-page {
    background-color: var(--primary-white);
  }
}

.popover-ot {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 2.8rem 0;
  min-width: 20rem;
  &__item {
    margin-bottom: 2rem;
    &__ondays {
      display: flex;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--primary-gray-1);
    }
    &__current {
      font-weight: 700;
      font-size: 1.6rem;
      margin-top: 0.2rem;
    }
  }
}

.dialog-detail {
  width: 43rem;
  &__content {
    padding: 2.8rem 5.8rem;
  }
  &__time {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 3rem;
    color: var(--primary-gray-1);
  }
  &__table {
    display: flex;
    &__label {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: var(--primary-gray-1);
      margin-bottom: 0.8rem;
    }
    &__val {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 0.4rem;
    }
  }
}
