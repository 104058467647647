// https://www.figma.com/file/TK7L8DOv9RFSEGq5IQANby/Greenfeed-Design-System?node-id=0%3A1
@import "../styles/media";

:root {
  --primary-white: #fff;
  --primary-blue-base: #00529c;
  --primary-blue-dark: #003565;
  --secondary-blue-base: #10bed2;
  --secondary-blue-1: #d8ecff;
  --secondary-blue-2: #2886db;
  --primary-dark: #272b2f;
  --primary-gray: #f0f3f8;
  --primary-gray-1: #525560;
  --primary-gray-2: #6c778d;
  --primary-gray-3: #dbe1ed;
  --primary-gray-6: #5a5959;
  --primary-gray-7: #aeb8c7;
  --secondary-gray: #bdc6d7;
  --navbar-max-width: 100%;
}

*,
*::after,
*::before,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
  font-size: 50%; // 8px
  @include from1200_ {
    font-size: 56.25%; // 9px
  }
  @include from1440_ {
    font-size: 62.5%; // 10px
  }
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito Sans", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ul[role="list"],
ol[role="list"] {
  list-style-type: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#notistack-snackbar {
  font-size: 1.4rem;
}

// scrollbar
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}
::-webkit-scrollbar-button {
  width: 0.6rem;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: var(--primary-gray-7);
  border: 0rem ridge var(--primary-gray-7);
  border-radius: 1.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-gray-8);
}
::-webkit-scrollbar-thumb:active {
  background: var(--primary-gray-8);
}
::-webkit-scrollbar-track {
  background: var(--primary-gray);
  border-radius: 0.2rem;
}
::-webkit-scrollbar-track:hover {
  background: var(--primary-gray);
}
::-webkit-scrollbar-corner {
  background: transparent;
}
