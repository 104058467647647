.resetpw-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  &__content {
    padding: 7rem 0rem 0rem 0rem;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5rem 0;
    }
    .title {
      width: 70%;
      margin-top: 4.5%;
      font-family: "Nunito Sans";
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 4.484rem;
      text-align: center;
    }
    .description {
      font-family: "Nunito Sans";
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 3.5rem;
      text-align: center;
      margin: 1.5rem 0rem 0rem 0rem;
    }
  }
  &__title {
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    text-align: left;
    margin-top: 2rem;
    .input {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 1.2rem !important;
      height: 2.4rem;
      font-family: "Nunito Sans";
    }
  }
  &__validate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Nunito Sans";
    font-size: 1.3rem;
    margin-top: 4rem;
    .validate-title {
      color: var(--primary-gray-6);
    }
    .validate-content {
      .item {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        .ic {
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          background: rgba(42, 199, 105, 0.24);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__submit {
    &-btn {
      width: 100%;
      border-radius: 4rem;
      height: 5.6rem;
      background-color: #0a6836;
      margin: 6rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 1.8rem;
      color: #ffffff;
      border: 1px solid #0a6836;
      cursor: pointer;
    }
  }
  &__text {
    font-family: "Nunito Sans";
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin-top: 20%;
    margin-bottom: 5rem;
  }
  .img-right {
    width: 40rem;
  }
  .img-left {
    width: 40rem;
  }
}
.button-summitt {
  display: flex;
  justify-content: center;
  &-btn {
    width: 100%;
    border-radius: 4rem;
    height: 5rem;
    background-color: #0a6836;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #ffffff;
    border: 1px solid #0a6836;
    cursor: pointer;
    margin: 4rem 0rem 0rem 0rem;
  }
}
.text-border-style {
  .MuiOutlinedInput-input {
    font-size: 1.3rem;
    font-family: "Nunito Sans";
  }
}
