.shift-modal {
  width: 65.7rem;
  font-family: "Nunito Sans";
  &__content {
    padding: 2.6rem 4.2rem;
    max-height: 72rem;
    overflow: auto;
  }
  &__form {
    text-align: center;
    .DateInput {
      width: 88%;
    }
    &__radio {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    &__title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.4rem;
      text-align: start;
      margin-bottom: 1.6rem;
    }
  }
  .modal-footer {
    button,
    input {
      width: 100%;
    }
  }
}

.notification-absence {
  max-height: 16rem;
  overflow: auto;
  font-size: 2rem;
  line-height: 3.1rem;
  margin-top: 0.9rem;
  &__item {
    border-bottom: 0.1rem solid #e0e6f0;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      border: 0;
    }
  }
}
