.notification-comp {
  font-family: "Nunito Sans";
  &__inner {
    padding: 9rem 2.4rem 6.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Nunito Sans";
    &__icon {
      .blue {
        background: #10bed2;
      }
      .green {
        background: #1ab759;
      }
      .red {
        background: #e93c3c;
      }
      .warning {
        background: #e89806;
      }
    }
    &__title {
      margin-top: 3.6rem;
      font-weight: bold;
      text-align: center;
      font-size: 3.5rem;
      line-height: 122%;
      color: #171725;
    }
    &__sub-title {
      margin-top: 1.2rem;
      text-align: center;
      font-size: 2rem;
      line-height: 3.1rem;
      color: #5a5959;
    }
    &__actions {
      width: 100%;
      margin-top: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 25.6rem;
        margin: 0 2.4rem;
      }
    }
  }
  .close-icon {
    font-size: 3rem;
    cursor: pointer;
    margin: 2rem 2rem;
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
  }
}
