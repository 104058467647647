.staff-detail {
  font-family: "Nunito Sans";
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    padding: 2.4rem 3rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
    .content-item {
      background-color: var(--primary-white);
      padding: 3.6rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      line-height: 3rem;
      &__title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.6rem;
        padding-bottom: 2.5rem;
      }
      &__label {
        color: var(--primary-gray-2);
        font-weight: 700;
      }
      &__money {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: right;
      }
    }
    &__left {
      width: 70%;
      margin-right: 2.4rem;
      padding: 0 !important;
      ::-webkit-scrollbar {
        display: none;
      }
      &__profile {
        display: flex;
        align-items: center;
        box-shadow: 0rem 0rem 4rem rgba(146, 151, 163, 0.1);
        border-radius: 0.8rem 0.8rem 0 0 !important;
        padding: 1.4rem 3.6rem;

        img {
          width: 8.3rem;
          height: 8.3rem;
          border-radius: 50%;
          margin-right: 3.5rem;
        }
        &__name {
          font-weight: 700;
          font-size: 2rem;
          line-height: 2.6rem;
        }
        &__text {
          color: var(--secondary-gray);
        }
        &__status {
          padding-top: 0.4rem;
          .statusStaffLabel {
            padding: 0.7rem 1.9rem;
            border-radius: 0.8rem;
            font-weight: 700;
          }
        }
      }
      &__info {
        padding: 0 3.6rem 3.6rem;
        overflow: auto;
        height: calc(100vh - 25rem);
        display: flex;
        flex-direction: column;
      }
      .content-item {
        &__title {
          padding-top: 3.6rem;
        }
      }
    }
    &__right {
      width: 30%;
      .btn-confirm-style {
        width: 100%;
        margin-top: 3.1rem;
      }
      .overtime-salary {
        border-radius: 0.8rem;
        border: 0.1rem solid #f0f3f8;
        background-color: #f9fbff;
        margin-top: 1rem;
        padding: 2.1rem 1.8rem;
        &__label {
          color: var(--primary-gray-1);
          font-weight: 700;
        }
        &__list {
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: 100%;
          max-height: calc(100vh - 64.5rem);
        }
      }
    }
  }
}
