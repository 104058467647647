.notfound {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito Sans";
  .title {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.2rem;
  }

  .label {
    margin-top: 1.2rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3.1rem;
    margin-bottom: 3.5rem;
    color: #312f30;
  }

  > img {
    margin: 3.5rem;
    width: 48.8rem;
    height: 26rem;
  }
}
