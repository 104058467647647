.staff-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans";
  .content {
    flex: 1;
    overflow: hidden auto;
    height: 100%;
    padding: 3rem 25rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  &__form {
    &__title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.6rem;
      padding: 1.8rem 0;
    }
    .single-range {
      .DateInput_input {
        padding: 1rem 1.6rem !important;
      }
    }
  }
  &__list-sub-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2.4rem;
    button {
      height: 3.6rem;
      margin-left: 1.2rem;
      z-index: 10;
    }
    .form-text-field {
      height: 3.6rem;
    }
  }
  &__title-add {
    display: flex;
    align-items: center;
    color: var(--primary-blue-base) !important;
    font-weight: 700 !important;
    font-size: 1.4rem !important;
    cursor: pointer;
    font-family: "Nunito Sans" !important;
    height: 3.6rem;
  }
  .SingleDatePickerInput_calendarIcon {
    float: right;
  }
}
