.overtime-salary-modal {
  border-radius: 0.8rem;
  border: 0.1rem solid #f0f3f8;
  background-color: #f9fbff;
  padding: 2.4rem;
  font-size: 1.6rem;
  font-family: "Nunito Sans";

  &__label {
    color: var(--primary-gray-1);
    font-weight: 700;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  &__check {
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Nunito Sans";
    padding-left: 0.6rem;
    &__active {
      font-weight: 700;
      line-height: 3rem;
    }
  }
  &__title-add {
    display: flex;
    align-items: center;
    color: var(--primary-blue-base);
    font-weight: 700;
    cursor: pointer;
  }

  .btn-remove {
    display: flex;
    align-items: center;
    color: #6c778d;
    font-weight: 700;
    cursor: pointer;
  }
}
