.mail-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans";
  .content {
    flex: 1;
    overflow: hidden auto;
    height: 100%;
    padding: 3.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70rem;
  }
  &__form {
    width: 100%;
    .setting-send {
      background-color: #f9fbff;
      border: 0.1rem solid #f0f3f8;
      border-radius: 0.4rem;
      padding: 1.8rem 2.4rem;
      font-size: 1.6rem;
      &__title {
        color: var(--primary-gray-1);
        font-size: 1.4rem;
        font-weight: 700;
      }
      .form-text-field {
        background-color: var(--primary-white);
        height: 3.6rem;
        width: 20.3rem;
        .MuiInputBase-root {
          height: 3.6rem;
        }
        input {
          padding: 0.8rem 1.6rem;
          font-size: 1.6rem;
        }
      }
      .radio-label {
        font-size: 1.6rem;
      }
    }
  }
  .single-range {
    width: auto;
    height: auto;
    .SingleDatePickerInput_calendarIcon {
      float: right;
    }
    .SingleDatePickerInput__withBorder {
      height: 3.6rem;
    }
    .SingleDatePicker {
      width: 22.7rem;
    }
    .DateInput_input {
      padding-top: 0.5rem !important;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0.5rem;
    }
  }
}

.mail-modal__select-menu-list {
  .MuiPaper-root {
    width: 8.8rem !important;
  }
}

/*item auto complete*/
.item-autocomplete {
  background: var(--primary-white);
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  padding: 1rem 2.4rem;
  cursor: pointer;
  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.8rem;
  }
  &__name {
    font-weight: 700;
  }
  &__id {
    font-size: 1.4rem;
    color: var(--primary-gray-2);
    padding-top: 0.2rem;
  }
}
