.floating-button-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-family: "Nunito Sans";
  opacity: 0.7;
  background-color: #272b2f;
}
.floating-button {
  position: absolute;
  bottom: 4.8rem;
  right: 2.8rem;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    transition: all 0.3s ease;
    &__list {
      width: 30rem;
      height: 10rem;
      box-shadow: 0px 4px 16px rgba(66, 78, 87, 0.16);
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      margin-right: 10rem;
    }
    &__circle {
      width: 5.8rem;
      height: 5.8rem;
      border-radius: 50%;
      box-shadow: 0px 4px 16px rgba(66, 78, 87, 0.16);
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      &.primary {
        background: var(--primary-blue-base);
        &:active {
          box-shadow: 0 0 11px 1px var(--primary-blue-base);
        }
        &.active {
          transform: rotate(45deg);
          background: #fff;
        }
      }
      &.blue {
        background: #2886db;
        &:active {
          box-shadow: 0 0 11px 1px #2886db;
        }
      }
      &.blue-green {
        background: #10bed2;
        &:active {
          box-shadow: 0 0 11px 1px #10bed2;
        }
      }
      &.red {
        background: #ff6262;
        &:active {
          box-shadow: 0 0 11px 1px #ff6262;
        }
      }
      &.dard-blue {
        background: #00529c;
        &:active {
          box-shadow: 0 0 11px 1px #00529c;
        }
      }
    }
    &__text {
      margin-top: 0.6rem;
      font-size: 1.4rem;
      text-align: center;
      color: var(--primary-white);
      font-weight: 700;
      &.hide {
        visibility: hidden;
        // display: none;
        opacity: 0;
      }
    }
  }
}
