@import "../../../assets/styles/button";

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  font-family: "Nunito Sans";
  .sub-route-menu {
    background-color: var(--primary-white);
    display: flex;
    padding: 0.6rem 1.2rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-top: 0.1rem solid #e0e6f0;
    .nav-item {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.6rem;
      margin-right: 1.6rem;
      border-radius: 100rem;
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--primary-dark);
      .icon {
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
      &-hover {
        &:hover {
          background: rgba(255, 255, 255, 0.223);
          @extend .btn--shadow;
        }
      }
      &__active {
        background-color: var(--secondary-blue-base);
        font-weight: 700;
        color: var(--primary-white);
      }
    }
  }
}
