.export-modal {
  width: 70rem;
  &__content {
    padding: 2.6rem 5.4rem;
  }
  &__label {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    display: flex;
    padding-bottom: 1.6rem;
  }
  &__num {
    color: var(--primary-white);
    font-size: 1.6rem;
    line-height: 1.3rem;
    background-color: var(--secondary-blue-2);
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8rem;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  &__check {
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Nunito Sans";
    padding-left: 0.6rem;
    &__active {
      font-weight: 700;
      line-height: 3rem;
    }
  }

  .modal-footer {
    button,
    input {
      width: 100%;
    }
  }
}
