.template-mail {
  .item-name {
    font-weight: 700;
    display: flex;
    align-items: center;
    img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0.8rem;
    }
  }
}
