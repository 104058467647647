@import "../../../assets/styles/media";
@import "../../../assets/styles/button";

.app-bar {
  background: var(--primary-white) !important;
  box-shadow: none !important;
  .app-toolbar {
    justify-content: space-between;
    display: flex;
    padding-left: 0;
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      button {
        width: 73px;
        height: 64px;
        background-color: var(--primary-blue-base);
        border-radius: 0;
        padding: 0 !important;
        margin: 0 !important;
        // position: absolute;
        // left: 1.7rem;
        // top: 0;
        // @include from1200_ {
        //   left: 1.7rem;
        // }
        // @include from1440_ {
        //   left: 1.5rem;
        // }
        // @include from1660_ {
        //   left: 1.2rem;
        // }
      }
      .title {
        color: var(--primary-dark);
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 3.6rem;
        font-family: "Nunito Sans";
        padding-left: 2.4rem;
      }
    }

    &-right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .notification {
        margin-right: 3rem;
        cursor: pointer;
      }

      > svg {
        margin-right: 1.5rem;
        cursor: pointer;
      }
      .menu-user {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        width: 4.8rem;
        height: 4.8rem;
        img {
          cursor: pointer;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          box-sizing: border-box;
          border: 0.1rem solid #d5d9e2;
          object-fit: contain;
        }
        &:hover {
          border: 0.1rem solid var(--primary-blue-base);
        }
      }
    }
  }
}

.DrawerHeader {
  // margin-right: 20px;
  // display: flex;
  // justify-content: space-between;
  &_img {
    width: 10.2rem;
    height: 3.7rem;
    object-fit: contain;
    margin-left: 15%;
  }
}

.nav-list {
  .nav-item {
    display: flex;
    margin: 1rem;
    @include from1200_ {
      margin: 1rem 0.8rem;
    }
    @include from1440_ {
      margin: 1rem 0.5rem;
    }
    @include from1660_ {
      margin: 1rem 0.6rem;
    }

    .label {
      font-family: "Nunito Sans";
      align-self: center;
      font-size: 1.4rem;
      margin-left: 2.5rem;
      text-transform: capitalize;
      font-weight: 700;
      @include from1660_ {
        margin-left: 1.2rem;
      }
    }
    .btn-hover {
      &:hover {
        background: var(--primary-blue-dark);
      }
    }
    &:hover {
      background: var(--primary-blue-dark);
      border-radius: 0.5rem;
    }
    .btn-icon-active {
      background: var(--primary-blue-dark);
    }
  }
  .away {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 85%;
    @include to667_ {
      position: relative;
    }
  }
}

.layout__content__body {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
