@import "../../../../assets/styles/button";

.app-toolbar-right-profile {
  font-family: "Nunito Sans";
  @include btn-font-style(700);
  color: rgba(15, 16, 18, 0.8);
  border-radius: 1rem;
  .name {
    display: flex;
    align-items: center;
    margin: 2.2rem;
    img {
      width: 3.6rem;
      height: 3.6rem;
      border: 0.1rem solid #d5d9e2;
      border-radius: 50%;
      margin-right: 1.3rem;
      object-fit: cover;
    }
    .email {
      font-weight: 400;
      font-size: 1.4rem;
      color: #7a869a;
      width: 16.3rem;
      word-wrap: break-word;
    }
  }
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f0f3f8;
    padding: 2.2rem 2rem;
    border-radius: 1rem;
    button {
      font-family: "Nunito Sans";
      background: transparent;
      border-radius: 0.6rem;
      border: 0;
      display: flex;
      align-items: center;
      padding: 1rem;
      width: 100%;
      cursor: pointer;
      outline: none;
      font-size: 1.6rem;
      font-weight: 700;
      &:hover {
        background: var(--primary-white);
        box-shadow: 0 0.4rem 1.6rem rgba(146, 151, 163, 0.24);
      }
    }
  }
}
