.other-insuranc {
  font-size: 1.6rem;
  width: 100%;
  font-family: "Nunito Sans";

  &__item {
    margin-bottom: 1.6rem;
  }
  &__title-add {
    display: flex;
    align-items: center;
    color: var(--primary-blue-base);
    font-weight: 700;
    cursor: pointer;
    margin-top: 2.5rem;
  }
  .btn-remove {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #6c778d;
    font-weight: 700;
    margin: 1.5rem 0;
    cursor: pointer;
    width: fit-content;
    float: right;
  }
}
