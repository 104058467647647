.edit-timeshift {
  min-width: 54.4rem;
  &__content {
    padding: 2.6rem 4.2rem;
    &__time {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 3rem;
      color: var(--primary-gray-1);
    }
  }
  .item-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    img {
      height: 6.4rem;
      width: 6.4rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.4rem;
    }
    .item-code {
      color: var(--primary-gray-2);
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  &__form {
    text-align: center;
    &__breaktime {
      color: var(--primary-gray-2);
      font-weight: 700;
      font-size: 1.6rem;
      text-align: left;
    }
  }
  .modal-footer {
    button,
    input {
      width: 100%;
    }
  }
}
