.time-sheet-history {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Nunito Sans";
  &__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem 2.8rem;
    height: 100%;
    &__action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.7rem;
      .range-picker-cpn {
        display: flex;
        .btn-back {
          margin: 0 0.6rem 0 0;
          width: 4rem;
        }
        .btn-forward {
          margin: 0 0 0 0.6rem;
          width: 4rem;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0.1rem solid #e0e6f0;
      }
      .date-range {
        border: 0.1rem solid #e0e6f0 !important;
        border-radius: 0.4rem !important;
      }

      .DateRangePicker,
      .DateRangePicker_1,
      .DateInput,
      .DateInput_1,
      .DateRangePickerInput {
        height: 4.3rem;
        border: 0;
        input {
          height: 4.3rem;
        }
      }
      .DateRangePickerInput_calendarIcon {
        padding: 1rem;
        float: left;
      }
      &__btn-time {
        margin: 0 !important;
        width: 25.5rem;
        &__text {
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin: 0 4.6rem 0 2rem;
        }
      }
      .MuiOutlinedInput-root {
        height: 4.5rem;
        padding: 0 !important;
      }
      .MuiIconButton-root {
        padding: 0.8rem 0.5rem;
      }
      .autocomplete-check__text-field {
        background-color: var(--primary-white);
        border-radius: 0.4rem;
      }
      .btn-cancel-style {
        font-weight: 400;
        border: 0.1rem solid #e0e6f0;
        padding: 1.2rem 2rem;
        height: 4.5rem;
        margin-left: 1rem;
      }
    }
    &__list {
      font-size: 1.4rem;
      line-height: 1.8rem;
      border-radius: 0.4rem;
      min-height: 10rem;
      width: 100%;
      overflow: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      &__employee {
        width: 20%;
      }
      &__works-hours {
        width: 12%;
      }
      &__paid-hours {
        width: 11%;
      }
      &__regular-hours {
        width: 11%;
      }
      &__overtime {
        width: 11%;
      }
      &__paid {
        width: 12%;
      }
      &__unpaid {
        width: 13%;
      }
      &__pay {
        width: 10%;
      }
      &__item-date {
        background-color: #f9fbff;
        border-bottom: 0.2rem solid #f0f3f8;
        padding: 0.6rem 2.4rem;
        color: var(--primary-gray-1);
        font-size: 1.4rem;
        font-weight: 700;
      }
      .item-list-page {
        .item-name {
          font-weight: 700;
          display: flex;
          align-items: center;
          line-height: 1.8rem;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 0.8rem;
          }
        }
        .item-code {
          color: #7a869a;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
  }
}

.popover-time {
  width: 25.5rem;
  padding: 1rem;
  &__text {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &__select {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  &__btn {
    display: flex;
    height: 4.5rem;
    margin-top: 2rem;
    .btn-cancel-style {
      margin-right: 0.5rem;
      width: 11.2rem;
    }
    .btn-confirm-style {
      margin-left: 0.5rem;
      width: 11.2rem;
    }
  }
}
