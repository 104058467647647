.absence-modal {
  width: 65.7rem;
  &__content {
    padding: 2.6rem 4.2rem;
    max-height: 59rem;
    overflow: auto;
  }
  &__form {
    text-align: center;
    .DateInput {
      width: 88%;
    }
    &__title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
      text-align: start;
    }
    .autocomplete-check__text-field {
      input {
        padding-left: 3.8rem !important;
      }
    }
  }
  .modal-footer {
    button,
    input {
      width: 100%;
    }
  }
}
