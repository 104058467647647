.choose-timeshift {
  font-family: "Nunito Sans";
  font-size: 1.6rem;
  line-height: 3rem;
  &__item {
    margin-bottom: 1.6rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #e0e6f0;
    &__form {
      padding: 2.4rem 2.4rem 0;
      &__breaktime {
        color: var(--primary-gray-2);
        font-weight: 700;
        font-size: 1.6rem;
        text-align: left;
      }
    }
    &__group {
      display: flex;
      justify-content: flex-end;
      color: var(--primary-gray-2);
      background-color: var(--primary-gray);
      font-weight: 700;
      padding: 0.5rem 2.4rem;
      &__btn {
        display: flex;
        align-items: center;
        border-left: 0.1rem solid #e0e6f0;
        margin-left: 2.4rem;
        cursor: pointer;
        &:first-child {
          border: 0;
        }
      }
    }
  }
  &__title-add {
    display: flex;
    align-items: center;
    color: var(--primary-blue-base);
    font-weight: 700;
    cursor: pointer;
    margin-top: 2.5rem;
  }
}
