$lines: 1, 2, 3, 4, 5;

@each $line in $lines {
  .text-overflow-#{$line}-lines {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
