.setting-listing {
  height: 100%;
  font-family: "Nunito Sans";
  &__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    &__left {
      display: flex;
      flex-direction: column;
      height: 100vh;
      position: relative;
      .btn-cancel-style {
        position: absolute;
        right: -1.5rem;
        top: 0;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.1rem solid #f0f3f8;
        z-index: 1201;
      }
      &__title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.6rem;
        padding: 1.8rem 2.8rem;
      }
      &__search {
        padding: 1.8rem;
        .search-style {
          background: #f0f3f8;
          border: 0.1rem solid #e0e6f0;
          height: 3.6rem;
        }
      }
      &__menu {
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        line-height: 1.8rem;
        &__item {
          display: flex;
          align-items: center;
          padding: 0.9rem 1.8rem;
          cursor: pointer;
          margin-bottom: 0.2rem;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: contain;
            margin-right: 1rem;
          }
          &:hover {
            background: #f9fbff;
          }
        }
        .menu-item-active {
          background: #effdff;
          color: #098b9a;
          font-weight: 700;
        }
      }
    }
    &__right {
      padding: 1.5rem 2.8rem;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2.7rem;
        .search-style {
          width: 40rem;
          margin-right: 1.5rem;
          height: 4rem;
        }
      }
      &__list {
        background-color: var(--primary-white);
        font-size: 1.4rem;
        line-height: 1.8rem;
        border-radius: 0.4rem;
        min-height: 10rem;
        width: 100%;
        overflow: auto;
        display: flex;
        height: 100%;
        flex-direction: column;
        &__email {
          width: 25%;
        }
        &__title {
          width: 9%;
        }
        &__name {
          width: 30%;
        }
        &__auto-email {
          width: 20%;
        }
        &__status {
          width: 10%;
        }
        &__edit {
          width: 3%;
          text-align: center;
        }
        &__del {
          width: 3%;
          text-align: center;
        }
      }
    }
  }
}
