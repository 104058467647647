.time-sheet {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Nunito Sans";
  &__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem 2.8rem;
    height: 100%;
    &__action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.7rem;
      .range-picker-cpn {
        display: flex;
        align-items: center;
        .btn-back {
          margin: 0 0.6rem 0 0;
          width: 4rem;
        }
        .btn-forward {
          margin: 0 0 0 0.6rem;
          width: 4rem;
        }
      }
      &__date {
        position: relative;
        align-items: center;
        display: flex;
        &__val {
          position: absolute;
          right: 10%;
          font-size: 1.4rem;
        }
      }
      .date__val__daily {
        .time-sheet__content__action__date__val {
          right: 30%;
        }
      }
      .single-range {
        width: 25.5rem;
        height: 4.5rem;
        .SingleDatePickerInput__withBorder {
          height: 4.5rem;
          border: 0.1rem solid #e0e6f0;
        }
        .DateInput_input {
          padding: 0 !important;
          opacity: 0;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0.1rem solid #e0e6f0;
      }
      .MuiOutlinedInput-root {
        height: 4.5rem;
        padding: 0 !important;
      }
      .btn-cancel-style {
        font-weight: 400;
        border: 0.1rem solid #e0e6f0;
        padding: 1.2rem 2rem;
        height: 4.5rem;
      }
      .autocomplete-check__text-field {
        background-color: var(--primary-white);
        border-radius: 0.4rem;
      }
      .MuiAutocomplete-root {
        margin-right: 0.8rem;
        height: 4.5rem;
      }
      .MuiIconButton-root {
        padding: 0.5rem;
      }
      .employee-check {
        input {
          &::placeholder {
            opacity: 1;
          }
        }
      }
    }
    &__list {
      font-size: 1.4rem;
      line-height: 1.8rem;
      border-radius: 0.4rem;
      min-height: 10rem;
      width: 100%;
      overflow: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      .timeList {
        color: #536380;
        min-width: 8rem !important;
        max-width: 8rem !important;
      }
      .header-list-page {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        .time-sheet__content__list__item__col {
          justify-content: center;
          min-height: 4.3rem;
          .dateVal {
            font-weight: 700;
            line-height: 3rem;
            padding-right: 0.8rem;
          }
        }
        .employ {
          border-radius: 0.8rem 0.8rem 0 0;
          padding: 1rem;
          min-width: 25rem;
          .name {
            color: #2d3742;
          }
          .totalTime {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-top: 0.2rem;
            font-weight: 400;
            color: var(--primary-gray-2);
          }
        }
        &__employ {
          &:first-child {
            min-width: 20rem;
            max-width: 20rem;
            border: 0;
            background: var(--primary-gray);
          }
        }
      }
      &__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        &__col {
          width: 100%;
          border: 0.1rem solid var(--primary-gray-3);
          border-left: 0;
          border-top: 0;
          min-height: 6.4rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: var(--primary-white);
          position: relative;
          &:first-child {
            // min-width: 20rem;
            // max-width: 20rem;
            border: 0;
            background: var(--primary-gray);
          }
          img {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            border-radius: 50%;
            margin-right: 0.8rem;
          }
          .name {
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 3rem;
          }
          .code {
            color: var(--primary-gray-2);
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-top: 0.2rem;
          }
          .tag {
            font-size: 1.2rem;
            line-height: 1.3rem;
            color: var(--primary-gray-1);
            background-color: #e6fcf7;
            border-radius: 0.3rem;
            border-left: 0.3rem solid #10bed2;
            padding: 1.1rem;
            cursor: pointer;
            margin-left: 0.8rem;
            &__time {
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 1.5rem;
              color: var(--primary-dark);
              margin-top: 0.3rem;
            }
          }
          .tagWeek {
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: var(--primary-gray-1);
            border-radius: 0.4rem;
            cursor: pointer;
            padding: 1.4rem 0;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            &__item {
              padding: 0 1.4rem;
            }
            &__title {
              font-weight: 700;
              margin: 0.4rem 0 0.9rem;
              font-size: 1.4rem;
            }
            &__time {
              display: flex;
              margin-top: 0.4rem;
              &__item {
                margin-right: 1.9rem;
                font-weight: 700;
                font-size: 1.4rem;
                &:last-child {
                  margin-right: 0;
                }
                &__label {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  font-weight: 400;
                  margin-bottom: 0.2rem;
                }
              }
            }
            img {
              margin-top: 1rem;
              width: 100%;
              height: 1.6rem;
              object-fit: cover;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}

.tooltip-time-sheet-detail {
  padding: 2.7rem;
  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 3rem;
    color: var(--primary-gray-1);
  }
  .item-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    img {
      height: 6.4rem;
      width: 6.4rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 1.4rem;
    }
    .item-code {
      color: var(--primary-gray-2);
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  &__item {
    font-size: 1.6rem;
    line-height: 2rem;
    border: 0.1rem solid #e0e6f0;
    border-radius: 0.4rem;
    padding: 0rem 1.8rem 2.4rem;
    margin-bottom: 2.4rem;
    &:last-child {
      margin-bottom: 0;
    }
    &__label {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 0.2rem;
      color: var(--primary-gray-1);
      padding-top: 2.4rem;
    }
  }
  &__list {
    overflow: auto;
    max-height: 33.5rem;
  }
}

.tagGreen {
  background-color: #e6fcf7;
  border-left: 0.3rem solid #10bed2;
}
.tagPaid {
  background-color: #d8ecff;
  border-left: 0.3rem solid #2886db;
}
.tagUnpaid {
  background-color: #f0f3f8;
  border-left: 0.3rem solid #bdc6d7;
}
.tagRed {
  background-color: #ffe7ec;
  border-left: 0.3rem solid #ff6262;
  display: flex;
  justify-content: space-between;
  &__icon {
    cursor: pointer;
    margin-right: 1.4rem;
  }
}
