@import "../../../assets/styles/text";

.staff-listing {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Nunito Sans";
  &__content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem 2.8rem;
    height: 100%;
    &__action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.7rem;
      &__title {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 700;
      }
      .search-style {
        width: 40rem;
        margin-right: 1.5rem;
        height: 4.5rem;
      }
      .btn-cancel-style {
        font-weight: 400;
        border: 0.1rem solid #e0e6f0;
        padding: 1.2rem 2rem;
        height: 4.5rem;
      }
    }

    &__list {
      background-color: var(--primary-white);
      font-size: 1.4rem;
      line-height: 1.8rem;
      border-radius: 0.4rem;
      min-height: 10rem;
      width: 100%;
      overflow: auto;
      display: flex;
      height: 100%;
      flex-direction: column;
      &__id {
        width: 20%;
      }
      &__name {
        width: 26%;
      }
      &__email {
        width: 28%;
      }
      &__phone {
        width: 20%;
      }
      // &__status {
      //   width: 6%;
      //   display: flex;
      //   justify-content: center;
      //   .statusStaff {
      //     width: 1rem;
      //     height: 1rem;
      //     border-radius: 50%;
      //   }
      // }
      &__edit {
        width: 3%;
        text-align: center;
      }
      &__del {
        width: 3%;
        text-align: center;
      }

      .item-list-page {
        .item-name {
          font-weight: 700;
          display: flex;
          align-items: center;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

.dialog-import-employee {
  font-family: "Nunito Sans";
  &__content {
    padding: 3.8rem 3rem 6rem;
    font-size: 1.6rem;
    line-height: 2rem;
    &__file {
      display: flex;
      font-weight: 600;
      margin-bottom: 1.6rem;
      &__link-color {
        color: var(--secondary-blue-2);
        margin-left: 4rem;
      }
    }
    &__upload {
      border: 0.1rem dashed var(--secondary-gray);
      border-radius: 1.2rem;
      background: var(--primary-gray);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30rem;
      position: relative;
      &__img {
        width: 7.4rem;
        height: 7.4rem;
        border-radius: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 7.4rem;
          height: 7.4rem;
          object-fit: contain;
          margin-bottom: 1.6rem;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
      }
      .selected-file {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 0.1rem solid #e0e6f0;
        width: 36.4rem;
        margin-top: 1rem;
        &__name {
          @extend .text-overflow-1-lines;
          text-overflow: ellipsis;
        }
      }
    }
    &__input {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: var(--primary-gray-2);
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }

      &__line {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.4rem;
      }
      .btn-cancel-style {
        background-color: transparent;
        height: 3.6rem;
        margin-top: 1.2rem;
      }
    }
    .err {
      color: #e93c3c;
      margin-top: 1.6rem;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  }
  .modal-footer {
    button,
    input {
      width: 100%;
    }
  }
}

.dialog-import-sc {
  padding: 3.2rem 3.7rem;
  &__clear {
    float: right;
    cursor: pointer;
  }
  &__content {
    min-width: 51rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      object-fit: contain;
      height: 12rem;
      margin-left: 5rem;
    }
    &__title {
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.4rem;
      margin: 2.2rem 0;
    }
    &__table {
      border: 0.1rem solid #e0e6f0;
      border-radius: 0.4rem;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;
      padding: 1.8rem 4.6rem;
      width: 35rem;
      &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;
        &:last-child {
          margin-bottom: 0;
          color: #e93c3c;
        }
        &__val {
          font-weight: 700;
        }
      }
    }
  }
  &__btn {
    display: flex;
    button {
      width: 100%;
      margin: 4.8rem 1rem 0;
    }
  }
}
