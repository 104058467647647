@import "../../../assets/styles/template";
@import "../../../assets/styles/button";

.modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 6rem;
  background-color: var(--primary-white);
  box-shadow: 6px 5px 21px rgba(108, 72, 58, 0.2);
  input[type="submit"] {
    @extend .btn-confirm-style;
    @extend .btn--shiny;
    outline: none;
    padding: 0.9rem 6rem !important;
  }
  button {
    padding: 0.9rem 6rem !important;
  }
  .btn-delete {
    @extend .btn-cancel-style;
    border: 1px solid #e53935;
    color: #f84948;
    outline: none;
  }
  .btn-cancel {
    @extend .btn-cancel-style;
    margin-left: auto;
    margin-right: 1.6rem;
    outline: none;
  }
}
