.date-range {
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePicker_picker{
    z-index: 2;
  }
  .DateRangePickerInput__withBorder {
    width: 100%;
  }
  .DateRangePickerInput_calendarIcon {
    float: right;
  }
  .DateInput_input__focused {
    border-bottom: 0.2rem solid var(--primary-blue-base);
  }
  .CalendarDay__selected_span {
    background: var(--secondary-blue-2);
    border: 0.1rem solid var(--secondary-blue-1);
  }
  .CalendarDay__selected {
    background: var(--primary-blue-base);
    border: 0.1rem double #0e76d9;
  }
}
